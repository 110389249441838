/** @format */
var lastScrollTop = 0;
if ("NodeList" in window && !NodeList.prototype.forEach) {
  console.info("polyfill for IE11");
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

(function () {
  /*
        HOME ARROW
        $('.arrow-home').click(function(event) {
            $('body, html').animate({
                scrollTop: $(window).height()-$('header').outerHeight()*2},
                800, 'easeOutExpo');
        });
    */

  let field_2012_5 = document.querySelector( "#form_id-2012 .field_2012_5" )
  if( field_2012_5 ){

    let field_2012_6 = document.querySelector( ".field_2012_6" )

    field_2012_5.addEventListener( "change", ( e ) => {
      if( e.target.value == "other" ){
        field_2012_6.classList.remove( "hidden" )
      }else{
        field_2012_6.classList.add( "hidden" )
      }
    } )
  }

  // Remove empty <p> tags created by tinymce
  const paragraph = document.querySelectorAll("p") || [];
  paragraph.forEach(
    (e) => e.innerHTML.trim() === "" && e.parentNode.removeChild(e)
  );
  var activeClass = "slick-active",
    ariaAttribute = "aria-hidden";
  $(".slider")
    .on("init", function () {
      $(".slick-dots li:first-of-type")
        .addClass(activeClass)
        .attr(ariaAttribute, false);
    })
    .on("afterChange", function (event, slick, currentSlide) {
      var $dots = $(".slick-dots");
      $("li", $dots).removeClass(activeClass).attr(ariaAttribute, true);
      $dots.each(function () {
        $("li", $(this))
          .eq(currentSlide)
          .addClass(activeClass)
          .attr(ariaAttribute, false);
      });
    });
  $(".slider").slick({
    prevArrow: ".arrows--prev",
    nextArrow: ".arrows--next",
    arrows: true,
    accessibility: false,
  });

  $('.slider-content').slick({
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    asNavFor: '.slider-image',
    responsive: [
      {
        breakpoint: 640,
        settings: {
          arrows: false,
          dots: true
        },
      },
    ],
  });

  $('.slider-image').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    centerMode: true,
    focusOnSelect: true, 
    asNavFor: '.slider-content',
  });

  $(".sym-slides").slick({
    accessibility: false,
  });
  $(".items__slider").slick({
    accessibility: false,
    arrows: true,
  });



  $('.slider_one').slick({
    asNavFor: '.slider_two, .slider_three', 
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    infinite: false
  });

  $('.slider_two').slick({
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.slider_one, .slider_three',
    fade:true

  });

  $('.slider_three').slick({
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.slider_one, .slider_two',
    fade:true,
  });


  $(".gallery-main").slick({
    dots: false,
    arrows: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          infinite: false,
          centerMode: false,
          mobileFirst: true,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          centerPadding: "400px",
        },
      },
      {
        breakpoint: 1850,
        settings: {
          centerPadding: "450px",
        },
      },
      {
        breakpoint: 1950,
        settings: {
          centerPadding: "500px",
        },
      },
      {
        breakpoint: 2000,
        settings: {
          centerPadding: "400px",
        },
      },
      {
        breakpoint: 2500,
        settings: {
          centerPadding: "400px",
        },
      },
    ],
  });

  $(".slick-tabs").slick({
    dots: true,
    focusOnSelect: true,
    infinite: true,
    arrows: true,
    customPaging: function (slider, i) {
      let title = $(slider.$slides[i]).data("title");
      return '<a class="locations-nav__page-item"> ' + title + " </a>";
    },
    prevArrow: ".arrows__prev",
    nextArrow: ".arrows__next",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          arrows: true,
          fade: true,
        },
      },
    ],
  });

  if ($(".top-reasons-sliders").length > 0) {
    $(".slider-images").slick({
      slidesToShow: 2,
      speed: 1000,
      arrows: true,
      asNavFor: ".slider-info",
      accessibility: true,

      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 3,
            infinite: true,
            swipe: false,
            accessibility: false,
          },
        },
      ],
    });

    $(".slider-info").slick({
      slidesToShow: 1,
      fade: true,
      speed: 1000,
      arrows: false,

      asNavFor: ".slider-images",
    });
  }
  if ($(".top-reasons-events").length > 0) {
    $(".images").slick({
      slidesToShow: 2,
      speed: 1000,
      adaptiveHeight: true,
      asNavFor: ".info",
      infinite: true,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 3,
            infinite: true,
            swipe: false,
          },
        },
      ],
    });
    $(".info").slick({
      slidesToShow: 1,
      fade: true,
      adaptiveHeight: true,
      speed: 1000,
      arrows: false,
      accessibility: true,
      asNavFor: ".images",
    });
  }

  // $("#0_email-signup_checkbox").on("change", () => {
  //   if ($(this).is(":checked"))
  //     $("#email-signup input[name=Data]").val(
  //       "Ok to Sell My Personal Information"
  //     );
  //   else
  //     $("#email-signup input[name=Data]").val(
  //       "Do Not Sell or Share My Personal Information"
  //     );

  //   console.log($("#email-signup input[name=Data]").val());
  // });
  
   // ====== FORMS LABEL FUNCTIONALITY
    // this fuction is used in the forms and email signup module
    // to move the label up when the input is focused
    function updateFormLabelsPosition({ inputsCollection }) {
      inputsCollection.forEach( input => {
          input.addEventListener('focus', function(e){
              input.previousElementSibling.style.transition = "all .3s ease-in-out";
              input.previousElementSibling.style.top = "-12px";
              input.previousElementSibling.style.left = "5px";
              input.previousElementSibling.style.fontSize = "11px";
          })
      });
  }

  if(document.querySelector('.form-module')) {
      let inputsCollection  = document.querySelectorAll('.form-module input, .form-module textarea, .form-module select');
      updateFormLabelsPosition( { inputsCollection });
  }

  if(document.querySelector('.email-signup')) {
      let inputsCollection = document.querySelectorAll('.email-signup .field.input input');
      updateFormLabelsPosition( { inputsCollection });
  }

})();
// Wrapper order checkbox
// $("#email-signup .checkbox").each(function () {
//   let allWrapper = $("<div/>").addClass("wrapper-flex");
//   $(this).before(allWrapper);
//   let next = $(this).next();
//   allWrapper.append(this).append(next);
// });

function scrollW() {
  //check scroll down or up for header styles
  let st = window.pageYOffset || document.documentElement.scrollTop;
  if (st > 80) {
    if (st > lastScrollTop) {
      // downscroll code
      if (!document.getElementById("header").classList.contains("down")) {
        document.getElementById("header").classList.add("down");
        document.getElementById("header").classList.remove("up");
      }
    } else {
      // upscroll code
      if (!document.getElementById("header").classList.contains("up")) {
        document.getElementById("header").classList.add("up");
        document.getElementById("header").classList.remove("down");
      }
    }
  } else {
    if (
      document.getElementById("header").classList.contains("up") ||
      document.getElementById("header").classList.contains("down")
    ) {
      document.getElementById("header").classList.remove("up");
      document.getElementById("header").classList.remove("down");
    }
    if(document.querySelector('#gallery-category')){
			document.getElementById('header').classList.add('down')
		}
  }
  lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
}

